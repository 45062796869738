import React from 'react';
import { Container, Typography, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container>
     <Typography variant="h4" gutterBottom>
     Privacy Policy




            </Typography>
            {/* <Typography variant="subtitle1" gutterBottom>
            Effective Date: [Insert Date]
            </Typography> */}
            <Typography paragraph>
            Welcome to TechInterviewMentor.com (“we,” “our,” or “us”). Your privacy is important to us, and this Privacy Policy explains how we collect, use, and protect your information when you use our website (“Website”) to schedule meetings or access our IT consulting and interview preparation services.
            </Typography>
            <Typography variant="h6" gutterBottom>
                1. Information We Collect
            </Typography>
            <Typography variant="subtitle1">1.1 Personal Information</Typography>
            <Typography variant="body1" paragraph>
                When you schedule a meeting or register on our Website, we may collect personal information, including but not limited to:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Your name" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Email address" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Phone number" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Payment information" />
                </ListItem>
            </List>

            <Typography variant="subtitle1">1.2 Non-Personal Information</Typography>
            <Typography variant="body1" paragraph>
                We may also collect non-personal information such as:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Browser type" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="IP address" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Device information" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Usage data and analytics" />
                </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
                2. How We Use Your Information
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Schedule and manage your appointments." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Provide you with consulting and interview preparation services." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Process payments and invoices." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Communicate updates or changes to services." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Improve our Website and user experience." />
                </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
                3. Sharing of Information
            </Typography>
            <Typography variant="body1" paragraph>
                We do not sell, rent, or share your personal information with third parties except:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="With service providers necessary to process payments or manage appointments." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="If required by law or in response to legal processes." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="To protect our rights, privacy, safety, or property." />
                </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
                4. Data Security
            </Typography>
            <Typography variant="body1" paragraph>
                We implement reasonable administrative, technical, and physical safeguards to protect your personal information. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.
            </Typography>

            <Typography variant="h6" gutterBottom>
                5. Your Rights
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Access, update, or delete your personal information." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Opt-out of marketing communications." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Request a copy of the data we hold about you." />
                </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
                6. Cookies and Tracking Technologies
            </Typography>
            <Typography variant="body1" paragraph>
                Our Website may use cookies and similar technologies to improve functionality and analyze usage. By using our Website, you consent to the use of cookies. You can manage cookie preferences through your browser settings.
            </Typography>

            <Typography variant="h6" gutterBottom>
                7. Changes to this Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                We reserve the right to update this Privacy Policy at any time. Changes will be effective upon posting on this page with the updated “Effective Date.”
            </Typography>

            <Typography variant="h6" gutterBottom>
                8. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                For questions or concerns about this Privacy Policy, please contact us at:
            </Typography>
            <Typography variant="body1" paragraph>
                Email: coolart25@gmail.com
            </Typography>
        </Container>
    );
};

export default PrivacyPolicy;