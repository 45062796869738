import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { RouterProvider } from 'react-router-dom';
import { RootRouter } from './routes';
import { Provider } from 'react-redux'
import { store } from './store/store';
const App = () => {

  return (
    <Provider store={store}>
    <RouterProvider router={RootRouter()} />
    </Provider>
  );
};

export default App;
