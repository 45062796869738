import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ReactTyped } from 'react-typed';

const WelcomeView = () => {
	const TYPED_STRINGS = [
		"a Mentor",
		"a Coach",
		"an Interviewer",
		"a Trainer",
	];

	return (
		<Box
			id={'welcome'}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				bgcolor: '#111418',
				color: '#FFFFFF',
				textAlign: 'center',
				height: '100vh',
				backgroundImage: 'url(https://img.freepik.com/free-photo/flat-lay-desk-dark-concept-with-copy-space_23-2148459468.jpg?t=st=1733552037~exp=1733555637~hmac=910156ab616fcddbf7f08631e1d62d1cdc21e9d711150178411420452b1ccfa9&w=996)',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				backgroundAttachment: 'fixed',
			}}
		>
			<Typography variant={'h4'} sx={{ color: '#FFFFFF' }}>
				{"Welcome"}
			</Typography>

			<Typography
				variant={'h3'}
				sx={{
					fontWeight: 700,
					lineHeight: 1.2,
					marginY: 2,
					fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
				}}
			>
				{"I am "} <span style={{ fontWeight: 'bold' }}>
					<ReactTyped
						strings={TYPED_STRINGS}
						typeSpeed={120}
						backSpeed={80}
						backDelay={1700}
						loop
					/>
				</span>
			</Typography>

			<Typography variant={'h5'} sx={{ color: '#FFFFFF' }}>
				{"based in Sunnyvale, California."}
			</Typography>
			<Button
				variant={'outlined'}
				sx={{
					marginY: 4,
					paddingX: 4.5, paddingY: 1.5,
					borderRadius: 99,
					border: '2px solid #20C997',
					color: '#20C997',
					bgcolor: 'transparent',
					textTransform: 'none',
					'&:hover': {
						borderColor: '#FFFFFF',
						bgcolor: '#333',
					},
				}}
			>
				{"Hire Me"}
			</Button>
		</Box>
	);
};

export default WelcomeView;
