import React from "react";
import { Box, Typography, Chip, Button, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import SendIcon from "@mui/icons-material/Send";

const AskMeAnythingCard = () => {
  return (
    <Box
      sx={{
        maxWidth: 400,
        maxHeight: 200,
        // marginTop:"20px",
        backgroundColor: "#fff",
        padding: 3,
        borderRadius:"50px",
        boxShadow: "3px 4px 10px rgba(0, 0, 0, 0.1)",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      {/* Rating and Badge Section */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 2 }}>
        <Chip
          icon={<StarIcon sx={{ color: "#FFD700" }} />}
          label="5"
          sx={{
            backgroundColor: "#f9f9f9",
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.8)",
            padding: "2px 8px",
          }}
        />
        <Chip
          label="Popular"
          sx={{
            backgroundColor: "#e3f2fd",
            color: "#1e88e5",
            fontWeight: "bold",
            padding: "2px 8px",
          }}
        />
      </Box>

      {/* Title Section */}
      <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 3 }}>
        Ask me anything
      </Typography>

      {/* Replies Section */}
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          borderRadius: 2,
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <SendIcon sx={{ fontSize: 36, color: "rgba(0, 0, 0, 0.6)" }} />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Replies in 2 days
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "12px" }}
            >
              Priority DM
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography
            sx={{
              textDecoration: "line-through",
              fontSize: "12px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            ₹100
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              textTransform: "none",
              padding: "4px 16px",
              borderRadius: "16px",
              fontWeight: "bold",
              minWidth: "unset",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
            endIcon={<SendIcon />}
          >
            ₹50+
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AskMeAnythingCard;
