import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const Footer = () => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        padding: 2,
        // backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        // boxShadow: '0 -2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Security Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          maxWidth: '800px',
          padding: 2,
          borderRadius: 2,
          backgroundColor: '#f5f7fb',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LockIcon sx={{ color: '#9ca3af' }} />
          <Typography variant="body2" sx={{ color: '#6b7280' }}>
            Payments are 100% secure & encrypted
          </Typography>
        </Box>
        <Box>
          <Link href="#" underline="none" sx={{ color: '#6b7280', fontSize: '14px', marginRight: 1 }}>
            Terms
          </Link>
          <Link href="#" underline="none" sx={{ color: '#6b7280', fontSize: '14px' }}>
            Privacy
          </Link>
        </Box>
      </Box>

      {/* Branding Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <img
          src="https://via.placeholder.com/16" // Placeholder icon, replace with your logo or icon URL
          alt="Topmate Icon"
          style={{ borderRadius: '50%' }}
        />
        <Typography variant="body2" sx={{ color: '#6b7280' }}>
          Powered by Sonia Jindal
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
