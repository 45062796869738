import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    IconButton,
    Grid,
    Collapse,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PeopleAlsoBought = (props: any) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [checked, setChecked] = useState(false);

    // useEffect(() => {
    //         props?.setSelectedAddOn(true);
    // },[])
    // useEffect(() => {
    //     props?.setSelectedAddOn(checked);
    // }, [checked])

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box
            sx={{
                maxWidth: 600,

                margin: 'auto',
            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 1,
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    People also bought
                </Typography>
                <IconButton onClick={toggleExpand} size="small">
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>

            {/* Collapsible Content */}
            <Collapse in={isExpanded} sx={{
                borderRadius: 4,

                padding: 2,
                backgroundColor: '#f5f5f5',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            }}>
                <Card
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        padding: 2,
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* Icon and Text */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flexGrow: 1,
                            paddingRight: 2,
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <FormControlLabel
                                control={<Checkbox value={checked} onChange={(e: any) => { setChecked(!checked); props.setSelectedAddOn(!checked)}} />}
                                label=""
                            />
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Get session recording
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                            Retain your learnings better
                        </Typography>
                    </Box>

                    {/* Price Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                textDecoration: 'line-through',
                                color: 'gray',
                                fontSize: '14px',
                            }}
                        >
                            $20
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                color: '#000',
                                fontSize: '18px',
                            }}
                        >
                            $10
                        </Typography>
                    </Box>
                </Card>

                {/* Additional Content */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 2,
                        backgroundColor: '#fff8e1',
                        padding: 2,
                        borderRadius: 2,
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        sx={{
                            width: 50,
                            height: 50,
                            borderRadius: 2,
                            backgroundColor: '#FF6F00',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 2,
                        }}
                    >
                        <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold' }}>
                            💡
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', marginBottom: 0.5 }}
                        >
                            Make core memories:
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Keep a lasting memory of your session
                        </Typography>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

export default PeopleAlsoBought;
