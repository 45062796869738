// import { ReactNode, useState } from "react";
// import {
//   Box,
//   Typography,
//   Grid,
//   Paper,
//   Avatar,
//   Rating,
//   Button,
//   Stack,
// } from "@mui/material";
// import HeadingView from "../typography/heading";
// import { ArrowBack, ArrowForward, Home } from "@mui/icons-material";
// import dataModal from "../../data/dataModal.json";
// import Slider from "react-slick";

// type BookingCalenderSliderViewProps = {
//   data: any;
// };

// const MyBookingCalender = () => {
//   return (
//     <Box
//       sx={{
//         backgroundColor: "#F8F9FA",
//         padding: { xs: "60px 20px", md: "80px 100px" },
//       }}
//     >
//       <HeadingView
//         backgroundTextColor={"#F1F3F4"}
//         backgroundText={"Booking"}
//         foregroundText={"Best Availability"}
//       />
//         <BookingCalenderSliderView data={dataModal.calenderDates} />

//     </Box>
//   );
// };

// const BookingCalenderSliderView = ({
//   data,
// }: BookingCalenderSliderViewProps) => {
//   const [selectedIndex, setSelectedIndex] = useState(0);

//   const settings = {
//     className: "slider variable-width",
//     dots: true,
//     infinite: true,
//     centerMode: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     variableWidth: true,
//   };

//   const handleSelect = (index: any) => {
//     setSelectedIndex(index);
//   };

//   return (
//     <Stack>
//       <Box
//         gap={2}
//         sx={{
//           padding: "20px",
//           borderRadius: "10px",
//           display: "flex",
//           flexDirection: "row",

//         }}
//       >
//         {data.map((item: any, index: any) => (
//           <Box key={index} sx={{ display: "flex", justifyContent: "center" }}>
//             <Button
//               onClick={() => handleSelect(index)}
//               sx={{
//                 display: "flex",
//                 width: "100px",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 padding: "15px 20px",
//                 border: "1px solid",
//                 borderRadius: "15px",
//                 borderColor: index === selectedIndex ? "#b8860b" : "#ccc",
//                 backgroundColor: index === selectedIndex ? "#f9f5ec" : "#fff",
//                 color: index === selectedIndex ? "#b8860b" : "#000",
//                 "&:hover": {
//                   backgroundColor:
//                     index === selectedIndex ? "#f9f5ec" : "#f0f0f0",
//                 },
//               }}
//             >
//               <Typography variant="body2" fontWeight="bold">
//                 {item.day}
//               </Typography>
//               <Typography variant="h6" fontWeight="bold">
//                 {item.date}
//               </Typography>
//             </Button>
//           </Box>
//         ))}
//       </Box>
//     </Stack>
//   );
// };

// export default MyBookingCalender;

import { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import HeadingView from "../typography/heading";
import dataModal from "../../data/dataModal.json";
import { data } from "react-router-dom";



const MyBookingTimeSlots = (props:any) => {
  const [selectedIndex, setSelectedIndex] = useState("");


  useEffect(()=>{
        setSelectedIndex("")
  },[props.selectedDate])

  return (
    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={74}>
      {dataModal.timeSlots.map((item:any, index:any) => (
        <ImageListItem key={item.name}>
          <Box
            onClick={() => {props.onSelect(item);setSelectedIndex(index);}}
            sx={{
              border: "2px solid #000",
              padding: "10px 30px",
              borderRadius: "10px",
              marginTop: "10px",
              // gap:3,
              // gridTemplateColumns:3
              height: 50,
              //   width: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderColor: index === selectedIndex ? "#20C997" : "#ccc",
              backgroundColor: index === selectedIndex ? "#f9f5ec" : "#fff",
              color: index === selectedIndex ? "#20C997" : "#000",
              "&:hover": {
                backgroundColor:
                  index === selectedIndex ? "#f9f5ec" : "#f0f0f0",
              },
              boxShadow: index === selectedIndex ? 3 : "none",
            }}
          >
            <Typography variant="h6">{item.name}</Typography>
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default MyBookingTimeSlots;
