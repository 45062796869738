import React from "react";
import {
  PanelView,
  TestimonialsView,
  ContactView,
  WelcomeView,
} from "../../components/home";
import { MyBookingCalenderView } from "../../components/confirmbooking";
import { Box, Button, Typography } from "@mui/material";

const TESTIMONIALS = [
  {
    name: "David Thompson",
    title: "Marketing Manager at ABC Corp",
    testimonial:
      "Sonia’s mentorship helped me understand the corporate structure and how to effectively communicate with executives. Thanks to her training, I successfully navigated internal challenges and advanced in my role.",
    avatarUrl: "https://randomuser.me/api/portraits/men/1.jpg",
    rating: 5,
  },
  {
    name: "Emily Garcia",
    title: "HR Director at TalentBridge",
    testimonial:
      "I had the privilege of working with Sonia when preparing a team for interviews. Her insights into how corporate structures function and her mock interviews were instrumental in helping our team crack interviews with top FAANG companies.",
    avatarUrl: "https://randomuser.me/api/portraits/women/2.jpg",
    rating: 5,
  },
  {
    name: "John Roberts",
    title: "Software Developer at InnovateTech",
    testimonial:
      "Sonia’s mock interview sessions were invaluable. With her guidance, I prepared for a role at Google and nailed my interview. Her strategies helped me understand the interview processes at FAANG companies like never before.",
    avatarUrl: "https://randomuser.me/api/portraits/men/3.jpg",
    rating: 5,
  },
  {
    name: "Sophia Martin",
    title: "Senior Consultant at Peak Solutions",
    testimonial:
      "Sonia’s deep knowledge of corporate structures helped me understand how to navigate organizational politics. Her advice made me more efficient at handling cross-functional teams, and I received a promotion shortly after.",
    avatarUrl: "https://randomuser.me/api/portraits/women/4.jpg",
    rating: 5,
  },
  {
    name: "Daniel Lee",
    title: "Product Manager at GreenTech",
    testimonial:
      "Sonia’s mentorship was a game-changer. Her mock interviews for Amazon and Microsoft were extremely detailed, helping me ace my interview process. She coached me on how to align my experience with company expectations, which made all the difference.",
    avatarUrl: "https://randomuser.me/api/portraits/men/5.jpg",
    rating: 5,
  },
  {
    name: "Olivia Brown",
    title: "Data Analyst at CityAnalytics",
    testimonial:
      "Thanks to Sonia’s training, I was able to transition into a leadership role at my company. Her insights on corporate hierarchy and understanding team dynamics helped me improve my decision-making and leadership skills.",
    avatarUrl: "https://randomuser.me/api/portraits/women/6.jpg",
    rating: 4,
  },
  {
    name: "Michael Clark",
    title: "Senior Developer at CodeCrafters",
    testimonial:
      "Sonia’s tailored interview coaching helped me secure a software engineering position at Facebook. She provided deep insights into the technical and cultural aspects of interviews at FAANG companies.",
    avatarUrl: "https://randomuser.me/api/portraits/men/7.jpg",
    rating: 5,
  },
  {
    name: "Isabella Evans",
    title: "Business Analyst at TechVision",
    testimonial:
      "Working with Sonia completely transformed my perspective on corporate structures. She helped me understand how to better align my goals with my company’s mission and enhance team performance.",
    avatarUrl: "https://randomuser.me/api/portraits/women/8.jpg",
    rating: 5,
  },
  {
    name: "Jacob Walker",
    title: "Sales Manager at FutureSales",
    testimonial:
      "Sonia helped me with both personal and team growth. Her mentorship not only helped me crack interviews at top companies but also helped me elevate my team’s performance through tailored training on communication and leadership.",
    avatarUrl: "https://randomuser.me/api/portraits/men/9.jpg",
    rating: 5,
  },
  {
    name: "Chloe Parker",
    title: "Operations Lead at Innovators Inc.",
    testimonial:
      "Sonia worked with my entire team to elevate our communication and rapport. The impact on our team dynamics was immediate, and we were able to handle projects more efficiently, leading to increased client satisfaction.",
    avatarUrl: "https://randomuser.me/api/portraits/women/10.jpg",
    rating: 5,
  },
  {
    name: "Liam Adams",
    title: "Marketing Strategist at BrightIdeas",
    testimonial:
      "Sonia’s advice helped me understand the intricate details of corporate structures and how to navigate them to elevate my career. Her approach was both practical and tailored to my individual needs.",
    avatarUrl: "https://randomuser.me/api/portraits/men/11.jpg",
    rating: 5,
  },
  {
    name: "Madeline Johnson",
    title: "Project Manager at SmartSolutions",
    testimonial:
      "Sonia mentored my team for a series of interviews at top-tier companies like Apple and Microsoft. Her coaching was instrumental in not only helping us understand what these companies look for but also in improving our team’s efficiency and collaboration.",
    avatarUrl: "https://randomuser.me/api/portraits/women/12.jpg",
    rating: 5,
  },
  {
    name: "Matthew Davis",
    title: "Operations Director at GlobalBiz",
    testimonial:
      "Sonia worked with me directly to improve my understanding of corporate operations. Her methods on aligning team goals with the company’s strategic vision were a turning point in my career. I now lead a highly efficient team.",
    avatarUrl: "https://randomuser.me/api/portraits/men/13.jpg",
    rating: 5,
  },
  {
    name: "Charlotte Scott",
    title: "Senior Analyst at DataForce",
    testimonial:
      "Sonia's mentorship helped me break into the corporate world with confidence. Her mock interview preparation, especially for top companies, taught me the importance of showcasing both skills and cultural fit.",
    avatarUrl: "https://randomuser.me/api/portraits/women/14.jpg",
    rating: 5,
  },
  {
    name: "Ethan Harris",
    title: "Chief Technology Officer at InnovTech",
    testimonial:
      "Sonia played a pivotal role in enhancing my team’s performance by providing tailored training in leadership and decision-making. Her deep understanding of corporate structures helped us elevate our internal processes.",
    avatarUrl: "https://randomuser.me/api/portraits/men/15.jpg",
    rating: 5,
  },
  {
    name: "Ava Green",
    title: "Client Success Manager at PartnerConnect",
    testimonial:
      "Sonia helped me understand how to align my career goals with the organizational structure. She provided in-depth insights on corporate culture that helped me excel in my current role at PartnerConnect.",
    avatarUrl: "https://randomuser.me/api/portraits/women/16.jpg",
    rating: 5,
  },
  {
    name: "Benjamin Mitchell",
    title: "Finance Manager at WealthBuilders",
    testimonial:
      "Sonia coached my entire team on how to improve their interview skills and navigate corporate structures. Her approach improved not only their individual performance but also the overall efficiency of our department.",
    avatarUrl: "https://randomuser.me/api/portraits/men/17.jpg",
    rating: 5,
  },
  {
    name: "Grace Taylor",
    title: "Content Strategist at InsightContent",
    testimonial:
      "Sonia’s training on corporate structures helped me understand the key drivers behind decision-making in larger organizations. She also coached me through the interview process at leading companies.",
    avatarUrl: "https://randomuser.me/api/portraits/women/18.jpg",
    rating: 5,
  },
  {
    name: "Zachary White",
    title: "IT Specialist at CloudTech Solutions",
    testimonial:
      "Sonia provided detailed insights into the structure of corporate teams and helped me develop the soft skills needed to communicate effectively at all levels. Thanks to her, I was able to secure a role at a leading tech company.",
    avatarUrl: "https://randomuser.me/api/portraits/men/19.jpg",
    rating: 5,
  },
  {
    name: "Samantha Robinson",
    title: "Head of Sales at BigReach Corp",
    testimonial:
      "Sonia worked with my team to elevate our performance in client-facing scenarios. Her training helped us better understand corporate structures, align with our company’s vision, and exceed our sales targets.",
    avatarUrl: "https://randomuser.me/api/portraits/women/20.jpg",
    rating: 5,
  },
];

const ConfirmBookingPage = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "250px", display: "flex", flexDirection: "column" }}>
        <PanelView name={"Sonia Jindal"} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {/* <WelcomeView /> */}
        {/* <AboutMeView /> */}

        <MyBookingCalenderView />
        {/* <MyServicesView /> */}
        {/* <ResumeView /> */}
        {/* <TestimonialsView testimonials={TESTIMONIALS}/> */}
        {/* <ContactView/> */}
      </Box>
     
    </Box>
  );
};

export default ConfirmBookingPage;
