import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  Grid,
  Chip,
  Paper,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HeadingView from "../typography/heading";
import dataModal from "../../data/dataModal.json";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { updateBookingData } from "../../store/features/booking";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { MyAskAnythingView, MyPackageView } from ".";
import Masonry from '@mui/lab/Masonry';
type ServiceItemTagsProps = {
  name: string;
};
type ServiceTabsItemProps = {
  name: string;
};
type ServiceItemViewProps = {
  title: string;
  description: string;
  tags?: ServiceItemTagsProps[];
  price: string;
  minutes: string;
  callType: string;
  type: string
};
const MyNewServicesView = () => {
  const [data, setData] = useState<ServiceItemViewProps[] | []>([]);
  const [tabs, setTabs] = useState<ServiceTabsItemProps[] | []>([]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  let navigate = useNavigate();
  let dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    console.log("dataModal : => ", dataModal);
    setTabs(dataModal.serviceTabs);
    setData([
      ...dataModal.callServices,
      ...dataModal.priorityDM,
      ...dataModal.packages,
    ]);
  }, []);

  const handleTabChange = (event: any, newValue: any) => {
    switch (newValue) {
      case 0:
        setData([
          ...dataModal.callServices,
          ...dataModal.priorityDM,
          ...dataModal.packages,
        ]);
        break;
      case 1:
        setData([...dataModal.callServices]);
        break;
      case 2:
        setData([...dataModal.priorityDM]);
        break;
      case 3:
        setData([...dataModal.packages]);
        break;

      default:
        setData([
          ...dataModal.callServices,
          ...dataModal.priorityDM,
          ...dataModal.packages,
        ]);
        break;
    }

    setSelectedTab(newValue);
  };
  return (
    <Box
      sx={{
        backgroundColor: "#F8F9FA",
        padding: { xs: "60px 20px", md: "80px 100px" },
      }}
    >
      <HeadingView
        backgroundTextColor={"#EDF0F1"}
        backgroundText={"Services"}
        foregroundText={"What I Do?"}
      />
      <Box
        sx={{ padding: "20px", backgroundColor: "#f5f5ef", minHeight: "100vh" }}
      >
        {/* Tabs */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            "& .MuiTabs-flexContainer": { gap: 4, color: "#000000", borderBottom: "0px" },
            "& .MuiTab-root": {
              border: "2px solid #000",
              borderRadius: "15px",
              fontSize: "20px",
              color: "#000",
              textTransform: "none",
            },
            "& .Mui-selected": {
              backgroundColor: "#000",
              color: "#ffffff",
              border: "0px",
              fontSize: "20px",

            },
          }}
        >
          <Tab label="All" />
          <Tab label="1:1 Call" />
          <Tab label="Priority DM" />
          <Tab label="Package" />
        </Tabs>
        {/* Cards */}

         
          <Masonry columns={2} spacing={2} sx={{ marginTop: "20px" }}>
            {data.map((service, index) => {
              return (
                service.type === "1:1" ?
                  <Grid item xs={12} sm={6} md={6} key={index}>
                    <Card
                      sx={{
                        borderRadius: "50px",
                        padding: "10px",
                        boxShadow: "3px 4px 10px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",

                      }}
                    >
                      <CardContent>
                        {service?.tags && service?.tags.map((item, index) => {
                          return (
                            <Chip
                              label={item.name}
                              color="primary"
                              sx={{
                                marginBottom: "10px",
                                backgroundColor: "#e3f2fd",
                                color: "#1976d2",
                                fontWeight: "bold"
                              }}
                            />
                          );
                        })}
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {service.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#757575", margin: "10px 0" }}
                        >
                          {service.description}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#f1f1f1",
                            padding: "20px",
                            marginTop: "50px",
                            borderRadius: "30px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <CalendarMonthIcon
                              sx={{ fontSize: "50px", color: "#757575" }}
                            />
                            <Box>
                              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {service.minutes} Mins
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: "500", color: "gray" }}>
                                {service.callType} Meeting
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "center" }}>
                            <Box
                              onClick={() => { dispatch(updateBookingData(service)); navigate("/booking") }}
                              sx={{
                                padding: "10px 10px 10px 10px",
                                border: "2px solid #000",
                                borderRadius: "50px",
                                display: "flex", alignItems: "center",
                                flexDirection: "row"
                              }}
                            >
                              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                ${service.price}{" "}

                              </Typography>
                              <ArrowForwardRounded
                                sx={{ fontSize: "30px", color: "#000" }}
                              />
                            </Box>
                            {/* <CalendarMonthIcon sx={{ fontSize: "50px", color: "#757575" }} /> */}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  :
                  service.type === "Ask" ? (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                      <MyAskAnythingView />
                    </Grid>)
                    :
                    service.type === "package" ? (
                      <Grid item xs={12} sm={6} md={6} key={index}>
                        <MyPackageView />
                      </Grid>)
                      : (<></>)
              )
            })}
            </Masonry>
          </Box>
      </Box>
  );
};

const ServiceItemView = (props: ServiceItemViewProps) => {
  return (
    <Grid>
      <Box sx={{ display: "flex", alignItems: "flex-start", padding: "20px" }}>
        <Paper
          sx={{
            // width: '30px',
            // height: '30px',
            display: "flex",
            justifyContent: "center",
            // alignItems: 'center',
            marginRight: "20px",
            padding: "30px",
            minHeight: "300px",
            maxWidth: "400px",
            borderRadius: "50px",
            boxShadow: 3,
            backgroundColor: "#FFFFFF",
          }}
        >
          <Box>
            <Typography
              variant={"h5"}
              sx={{ color: "#000000", fontWeight: "600" }}
            >
              {props.title}
            </Typography>
            <Typography sx={{ color: "#000000", marginTop: "10px" }}>
              {props.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the end of the container
                alignItems: "flex-end", // Aligns items to the bottom of the container
                height: "70%",
              }}
            >
              <Paper
                sx={{
                  // width: '30px',
                  // height: '30px',

                  // alignItems: 'center',
                  // marginRight: '20px',
                  // marginTop:"60px",
                  padding: "20px",
                  borderRadius: "30px",
                  boxShadow: 3,
                  backgroundColor: "#808080",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <CalendarMonthIcon
                      sx={{ color: "#20C997", fontSize: "28px" }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: "#000000" }}>
                      {props.description}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
};

export default MyNewServicesView;
