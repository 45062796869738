import React, { useEffect } from 'react';
import { TextField, Button, Grid, FormControlLabel, Checkbox, Typography, Box, TextFieldProps, FilledTextFieldProps, IconButton, Avatar } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBack from "@mui/icons-material/ArrowBack";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from 'react-router-dom';

import 'react-phone-input-2/lib/style.css'; // Import default styles
import PhoneInput from 'react-phone-input-2';
import OrderSummary from './orderSummary';
import PeopleAlsoBought from './peopleAlsoBought';
import DiscountCode from './applyDiscount';
import Footer from './footer';
import { useSelector } from 'react-redux';
const CareerGuidanceForm = () => {
  let navigate = useNavigate();
  // let bookingSessionData;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [appliedDiscount, setAppliedDiscount] = React.useState("")
  const [totalChargableAmonut, setTotalChargableAmount] = React.useState("");
  const [selectedAddOn, setSelectedAddOn] = React.useState("")

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [callFor, setCallFor] = React.useState("")
  const [phone, setPhone] = React.useState("");

  const [receiveDetail, setReceiveDetail] = React.useState(true)


  const booking = useSelector((state:any) => state.booking);
  
  useEffect(() => {
    console.log("bookingSessionData : ",booking.data);
    setTotalChargableAmount("")
  }, [selectedAddOn, appliedDiscount]);

  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
  };

  const handlePhoneChange = (value: any, country: any) => {
    console.log('Selected Country:', country);
    console.log('Phone Value:', value);
    setPhone(`+${value}`)
  };
  const addMinutes = (time:any,mint:any) => {
    const [hours, minutesPart] = time.split(":");
    const [minutes, period] = minutesPart.split(/(AM|PM)/);
    let totalMinutes = (parseInt(hours) % 12) * 60 + parseInt(minutes) + (period === "PM" ? 720 : 0) + mint;
    let newHours = Math.floor(totalMinutes / 60) % 12 || 12;
    let newMinutes = totalMinutes % 60;
    let newPeriod = totalMinutes >= 720 && totalMinutes < 1440 ? "PM" : "AM";
    return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")} ${newPeriod}`;
  };

  return (
    <Box sx={{ maxWidth: 500, margin: '0 auto', padding: 3, backgroundColor: '#fff', borderRadius: 2 }}>
      <Grid container spacing={2} overflow={"hidden"}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "16px",
              borderBottom: "1px solid #e0e0e0",
              backgroundColor: "#fff",
            }}
          >
            {/* Left Section: Back Button and Profile */}
            <Box sx={{ display: "flex", alignItems: "flex-start", marginLeft: "-20px",justifyContent:"center" }}>
              <IconButton onClick={() => navigate("/booking")}>
                <ArrowBack sx={{ color: "#000" }} />
              </IconButton>
              <Avatar
                src="https://via.placeholder.com/40" // Replace with actual profile picture URL
                alt="Mohit Motwani"
                sx={{ width: 40, height: 40, marginLeft: "8px" }}
              />
              <Typography sx={{ marginLeft: "8px", fontWeight: "bold",marginTop:"8px" }}>
                Sonia Jindal
              </Typography>
            </Box>

            {/* Right Section: Rating */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                borderRadius: "16px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <StarIcon sx={{ color: "#fbc02d", fontSize: "18px", marginRight: "4px" }} />
              <Typography sx={{ fontWeight: "bold" }}>5/5</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "16px",
              backgroundColor: "#fff",
            }}
          >
            {/* Title */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                marginBottom: "8px",
              }}
            >
              {booking.data?.title} 
            </Typography>

            {/* Subtitle */}
            <Typography
              variant="body1"
              sx={{
                color: "#757575", // Grey color for subtitle
              }}
            >
              {booking.data?.callType} Call | {booking.data?.minutes} Mins
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '16px',
              borderRadius: '8px',
              backgroundColor: '#f5f5f5',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              width: "470px"
            }}
          >
            {/* Date Icon and Info */}
            <Box sx={{ display: 'flex', alignItems: 'center', }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  marginRight: '16px',
                }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                {booking.data?.selectedDate.date.split(" ")[1]}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {booking.data?.selectedDate.day}, {booking.data?.selectedDate.date}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {addMinutes(booking.data?.selectedTime.name,0)} – {addMinutes(booking.data?.selectedTime.name,30)} (GMT +05:00)
                </Typography>
              </Box>
            </Box>

            {/* Change Button */}
            <Button
              variant="outlined"
              sx={{
                borderRadius: '16px',
                textTransform: 'none',
              }}
              onClick={() => navigate("/booking")}
            >
              Change
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>
            Name
          </Typography>
          <TextField variant="outlined" fullWidth value={name} onChange={(e: any) => setName(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>
            Email
          </Typography>
          <TextField variant="outlined" fullWidth value={email} onChange={(e: any) => setEmail(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>
            What is the call about?
          </Typography>
          <TextField variant="outlined" fullWidth value={callFor} onChange={(e: any) => setCallFor(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>
            Phone number
          </Typography>
          <Box sx={{
            width: '99%',
            height: '55px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            "&:hover": { border: '1px solid #000', }

          }}>
            <PhoneInput
              country={'pk'} // Default country set to Pakistan
              onChange={(value: any, country: any) => handlePhoneChange(value, country)}
              inputStyle={{
                width: '100%',
                height: '50px',
                fontSize: "16px",
                // borderRadius: '4px',
                border: '0px solid #ccc',
                // paddingLeft: '48px',

              }}
              buttonStyle={{
                border: 'none',
                backgroundColor: 'transparent',
                padding: '0',
              }}
              dropdownStyle={{
                zIndex: 10,
              }}
            />
          </Box>

        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox value={receiveDetail} onChange={(e: any) => setReceiveDetail(e.target.value)} />}
            label="Receive booking details on phone"
          />
        </Grid>




        <Grid item xs={12}>
          <DiscountCode appliedDiscount={(e: any) => setAppliedDiscount(e)} />
        </Grid>
        <Grid item xs={12}>
          <PeopleAlsoBought setSelectedAddOn={(e: any) => setSelectedAddOn(e)} />
        </Grid>
        <Grid item xs={12}>
          <OrderSummary data={booking.data} selectedAddOn={selectedAddOn} totalAmount={(e:any)=>setTotalChargableAmount(e)} />
        </Grid>
      </Grid>
      <Footer />
      <Grid marginTop={2} position='sticky' item xs={12} display="flex" justifyContent="space-between" alignItems={"center"}>
        <Typography variant="h6">${totalChargableAmonut}</Typography>
        <Button sx={{ width: "350px" }} variant="contained" color="primary" size="large">Confirm and Pay</Button>
      </Grid>
    </Box>
  );
};

export default CareerGuidanceForm;
