import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    IconButton,
    Divider,
    Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const OrderSummary = (props: any) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const addTotalAmount = (price: any, pf: any, ad: any) => {
        let total = 0;
        if(props.selectedAddOn){
            total = total+parseInt(price) + 5 + 10;
        }else{
            total = total+parseInt(price) + 5;
        }
        props.totalAmount(total)
        return total

    }
    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: '10px auto',

            }}
        >
            {/* Header Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 1,
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Order Summary
                </Typography>
                <IconButton onClick={toggleExpand} size="small">
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>

            {/* Collapsible Content */}
            <Collapse in={isExpanded} sx={{
                padding: 2,
                borderRadius: 4,
                backgroundColor: '#f9f9f9',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            }}>
                <Box>
                    {/* Order Items */}
                    <Grid
                        container
                        justifyContent="space-between"
                        sx={{
                            marginBottom: 1,
                            padding: '8px 0',
                        }}
                    >
                        <Typography sx={{ maxWidth: "350px" }}>1 x {props.data.title} - {props.data.minutes} Mins</Typography>
                        <Typography>${props?.data.price}</Typography>
                    </Grid>
                    <Divider />
                    {props.selectedAddOn && (<Grid
                        container
                        justifyContent="space-between"
                        sx={{
                            marginBottom: 1,
                            padding: '8px 0',
                            marginTop: "10px"
                        }}
                    >
                        <Typography>Add on: Recording</Typography>
                        <Typography>$10</Typography>
                    </Grid>)}
                    {props.selectedAddOn && (  <Divider />)}
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                             marginTop: "10px",
                            marginBottom: 1,
                            padding: '8px 0',
                        }}
                    >
                        <Typography>
                            Platform fee{' '}
                            {/* <Typography
                component="span"
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: '#777',
                }}
              >
                (i)
              </Typography> */}
                        </Typography>
                        <Typography><Typography variant="overline" sx={{ fontSize: 14, textDecorationLine: "line-through" }}>$10</Typography> $5</Typography>
                    </Grid>
                    <Divider />

                    {/* Total Section */}
                    <Grid
                        container
                        justifyContent="space-between"
                        sx={{
                            marginTop: 1,
                            padding: '8px 0',
                            fontWeight: 'bold',
                        }}
                    >
                        <Typography>Total</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>${addTotalAmount(props.data.price, 5, props.selectedAddOn ? 5 : 0)}</Typography>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
};

export default OrderSummary;
