import React from 'react';
import { Container, Typography, List, ListItem, ListItemText } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Terms and Conditions
            </Typography>
            {/* <Typography variant="subtitle1" gutterBottom>
                Effective Date: [Insert Date]
            </Typography> */}
            <Typography>
                These Terms and Conditions (“Terms”) govern your use of TechInterviewMentor.com (“Website”). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree, do not use our Website.
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="1. Services" secondary="TechInterviewMentor.com offers services such as interview preparation and IT consulting. Services are provided through scheduled meetings and are subject to availability." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="2. User Responsibilities" secondary="By using our Website, you agree to: Provide accurate and up-to-date information during registration or appointment scheduling. Use the Website and services for lawful purposes only. Not engage in activities that harm our Website or its users." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="3. Payment and Refund Policy" secondary="All payments must be made at the time of booking or as otherwise agreed. Refunds for cancellations will be handled per our cancellation policy detailed on the Website." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="4. Intellectual Property" secondary="All content, logos, and materials on the Website are owned by TechInterviewMentor.com and protected by intellectual property laws. You may not use, copy, or distribute our materials without prior written consent." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="5. Limitation of Liability" secondary="We strive to provide accurate and high-quality services. However, we are not liable for: Outcomes resulting from the advice or services provided. Any loss or damage arising from the use of our Website." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="6. Cancellation and Rescheduling" secondary="You may cancel or reschedule your appointment in accordance with the policy detailed on the Website. Failure to attend a scheduled meeting without prior notice may result in forfeiture of fees." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="7. Termination" secondary="We reserve the right to terminate your access to the Website or services for violation of these Terms or any applicable law." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="8. Governing Law" secondary="These Terms are governed by the laws of USA." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="9. Changes to Terms" secondary="We may update these Terms at any time. Changes will be effective upon posting to this page with the updated “Effective Date.”" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="10. Contact Us" secondary="For questions or concerns about these Terms, please contact us at: Email: coolart25@gmail.com" />
                </ListItem>
            </List>
        </Container>
    );
};

export default TermsAndConditions;