import {
  Route,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { BookingPage, HomePage, PayPage,TermsPage,PrivacyPage } from "../pages";

const RootRouter = () => {
  return createHashRouter(
    createRoutesFromElements(
      <>
        <Route path={"/"} element={<HomePage />} />
        <Route path={"/booking"} element={<BookingPage />} />
        <Route path={"/pay"} element={<PayPage />} />
        <Route path={"/terms"} element={<TermsPage />} />
        <Route path={"/privacy"} element={<PrivacyPage />} />
      </>
    )
  );
};

export default RootRouter;
