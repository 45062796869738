import { useEffect, useRef, useState } from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import HeadingView from "../typography/heading";
import dataModal from "../../data/dataModal.json";
import MyBookingTimeSlots from "./bookingslots";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { updateBookingData } from "../../store/features/booking";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';

import { loadClientAuth2, gapi } from "gapi-script";
import { log } from "console";

type BookingCalenderSliderViewProps = {

};


const CLIENT_ID = "662118066362-cr7064f8a7jm6ed2q3i5fg9t4bfhkln1.apps.googleusercontent.com"; // Replace with your Google Client ID
const API_KEY = "AIzaSyBv8973eWqDTRSu6npNyBEMiiayrqohfTQ"; // Replace with your Google API Key
const SCOPES = //[
  "https://www.googleapis.com/auth/calendar.events"
// , "https://www.googleapis.com/auth/calendar.readonly"]; //
// gapi.client
//   .init({
//     apiKey: API_KEY,
//     clientId: CLIENT_ID,
//     discoveryDocs: [
//       "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
//     ],
//     scope: SCOPES,
//   })

const MyBookingCalender = () => {
  let navigate = useNavigate();
  let dispatch: AppDispatch = useDispatch()
  const booking = useSelector((state: any) => state.booking);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [events, setEvents] = useState([]);
  const initClient = () => {
    // let gapiClient = loadClientAuth2(gapi, CLIENT_ID, SCOPES);
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
        ],
        scope: SCOPES,
      })
      .then(() => {
        console.log("Google API initialized");
        // gapi.client.calendar.events
        // .list({
        //   calendarId: "primary", // Fetch events from the primary calendar
        //   timeMin: new Date().toISOString(), // Start time for fetching events
        //   timeMax: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // Fetch events for the next 7 days
        //   showDeleted: false,
        //   singleEvents: true,
        //   orderBy: "startTime",
        // })
        gapi?.auth2?.getAuthInstance()?.signIn({
          scope: "https://www.googleapis.com/auth/calendar.events",
        }).then(async(res:any)=>{
          console.log(" user login success full",res.xc.access_token);
          // gapi.client.load("calendar", "v3", () => {
          // gapi.client.calendar.freebusy.query({
          //   timeMin: new Date("2024-12-19T10:00:00+05:00").toISOString(), // 10:00 AM (local timezone)
          //   timeMax: new Date("2024-12-19T22:00:00+05:00").toISOString(), // 9
          //   items: [{ id: "primary" }],
            
          // })
          const requestBody = {
            timeMin: "2024-12-19T10:00:00Z",
            timeMax: "2024-12-19T21:30:00Z",
            items: [{ id: "primary" }],
          };
          const response = await fetch("https://www.googleapis.com/calendar/v3/freeBusy", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${res.xc.access_token}`, // Include the access token
            },
            body: JSON.stringify(requestBody),
          });
        
          if (response.ok) {
            const result = await response.json();
            console.log("Free/Busy Response:", result);
          } else {
            const error = await response.json();
            console.log("Error fetching free/busy information:", error);
          }
        //  await fetch("https://www.googleapis.com/calendar/v3/freeBusy", {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${res.xc.access_token}`, // Include the access token
        //     },
        //     body: JSON.stringify(requestBody),
        //   })
        //     .then(async(response: any) => {
        //       const result = await response.json();
        //       console.log(result);
              
        //       const events = result.calendars.primary.busy;
        //       console.log("Events fetched:", events);
        //       if (events.length !== 0) {
        //         setEvents(events);
  
        //       } else {
        //         createEvent("2024-12-19T10:00:00+05:00", "2024-12-19T10:30:00+05:00")
        //       }
        //     })
        //     .catch((error: any) => {
        //       console.log("Error fetching events", error);
        //     });
        })
        .catch((err: any) => console.log("Error Freebusy events Google API", err));
      })
      .catch((err: any) => console.log("Error initializing Google API", err));
  };

  useEffect(() => {
    console.log("booking data : ", booking);
    gapi.load("client:auth2", initClient);

  }, [])

  const createEvent = async (startTime: any, endTime: any) => {
    console.log("sTartTIme: ", startTime);
    console.log("endTIme: ", endTime);

    try {
      const event = {
        summary: "Meeting Scheduled via Digital Resume",
        description: "This meeting was scheduled by a visitor.",
        start: {
          dateTime: startTime,
          timeZone: "Asia/Karachi",
        },
        end: {
          dateTime: endTime,
          timeZone: "Asia/Karachi",
        },
        attendees: [
          { email: "mudasirshahbaz786@gmail.com" }, // Visitor's email
        ],
        reminders: {
          useDefault: true,

        },
      };

      console.log("event Details : ", event);

      const request = gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
      });

      console.log("event request :", request);

      await request.execute((eventResponse: any) => {
        console.log("Event created : ", eventResponse);
        alert(`Meeting created successfully! View it here: ${eventResponse.htmlLink}`);
      });
    } catch (error) {
      console.log("Error creating event:", error);
    }
  };

  // useEffect(() => {



  //   gapi?.auth2?.getAuthInstance()?.signIn()
  //     .then(() => {
  //       gapi.client.calendar.events
  //         .list({
  //           calendarId: "primary", // Fetch events from the primary calendar
  //           timeMin: new Date().toISOString(), // Start time for fetching events
  //           timeMax: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // Fetch events for the next 7 days
  //           showDeleted: false,
  //           singleEvents: true,
  //           orderBy: "startTime",
  //         })
  //         .then((response: any) => {
  //           const events = response.result.items;
  //           console.log("Events fetched:", events);
  //           setEvents(events);
  //         })
  //         .catch((error: any) => {
  //           console.error("Error fetching events", error);
  //         });
  //     });





  // }, []);





  return (
    <Box
      sx={{
        backgroundColor: "#F8F9FA",
        padding: { xs: "60px 20px", md: "80px 100px" },
      }}
    >
      <HeadingView
        backgroundTextColor={"#F1F3F4"}
        backgroundText={"Booking"}
        foregroundText={"Best Availability"}
      />
      <BookingCalenderSliderView
        data={dataModal.calenderDates}
        onChange={(d: any) => {
          setSelectedDate(d)
          setSelectedTime("")
        }}
      />
      <Box
        sx={{
          display: "flex",
          // gap:3,
          justifyContent: "center",
          alignItems: "center",
          // justifyItems:"center",
          // overflowX:"scroll":false
        }}
      >
        <MyBookingTimeSlots selectedDate={selectedDate} onSelect={(t: any) => { setSelectedTime(t) }} />


      </Box>
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Button
          variant="contained"
          disabled={selectedTime ? false : true}
          onClick={() => { dispatch(updateBookingData({ ...booking.data, selectedDate: selectedDate, selectedTime: selectedTime })); navigate("/pay") }}
          sx={{
            backgroundColor: '#20C997',
            borderRadius: '50px',
            minWidth: 200,
            padding: '10px 30px',
            fontSize: '16px',
            color: '#FFF',
            '&:hover': { backgroundColor: '#1BAF84' },
          }}
        >
          {"Confirm"}
        </Button>
      </Box>
    </Box>
  );
};

const BookingCalenderSliderView = ({
  data,
  onChange,
}: any) => {
  useEffect(() => {
    onChange(data[selectedIndex])
  }, [])
  const [selectedIndex, setSelectedIndex] = useState(0);
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    // Smoothly scroll to the selected element
    itemRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const handlePrev = () => {
    const prevIndex = (selectedIndex - 1 + data.length) % data.length;
    setSelectedIndex(prevIndex);
    itemRefs.current[prevIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  const handleNext = () => {

    const nextIndex = (selectedIndex + 1) % data.length;
    setSelectedIndex(nextIndex);
    itemRefs.current[nextIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });

  };

  return (
    <Stack alignItems="center" spacing={2}>
      {/* Slider Navigation */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: "80vw",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handlePrev}
          sx={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            minWidth: "unset",
            backgroundColor: "#F0F0F0",
            "&:hover": { backgroundColor: "#E0E0E0" },
          }}
        >
          <ArrowBack sx={{ color: "#20C997" }} />
        </Button>

        {/* Calendar Dates */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            overflowX: "auto",
            padding: "10px",
            alignSelf: "center",
            maxWidth: "50vw",
            overflow: "hidden",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {data.map((item: any, index: any) => (
            <Button
              key={index}
              ref={(el) => (itemRefs.current[index] = el)}
              onClick={() => { handleSelect(index); onChange(item) }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 20px",
                minWidth: 150,
                border: "2px solid",
                borderRadius: "15px",
                borderColor: index === selectedIndex ? "#20C997" : "#ccc",
                backgroundColor: index === selectedIndex ? "#f9f5ec" : "#fff",
                color: index === selectedIndex ? "#20C997" : "#000",
                "&:hover": {
                  backgroundColor:
                    index === selectedIndex ? "#f9f5ec" : "#f0f0f0",
                },
                boxShadow: index === selectedIndex ? 3 : "none",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                {item.day}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                {item.date}
              </Typography>
            </Button>
          ))}
        </Box>

        <Button
          onClick={handleNext}
          sx={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            minWidth: "unset",
            backgroundColor: "#F0F0F0",
            "&:hover": { backgroundColor: "#E0E0E0" },
          }}
        >
          <ArrowForward sx={{ color: "#20C997" }} />
        </Button>
      </Box>
    </Stack>
  );
};

export default MyBookingCalender;
