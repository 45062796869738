import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const DiscountCode = (props:any) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [discountCode, setDiscounCode] = useState("");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      sx={{
        // maxWidth: 400,
        // margin: 'auto',
        // padding: 2,
        // borderRadius: 4,
        // backgroundColor: '#f9f9f9',
        // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Header with Toggle Button */}
      {!isExpanded&&<Box
      onClick={toggleExpand}
        sx={{
            maxWidth:"200px",
            color:"#000",
            height:60,
            borderRadius:20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: "#F0F0F0",
          "&:hover": { backgroundColor: "#E0E0E0" },
        }}
      >
        
        <Typography
            // variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize:"16px"
            }}
          >
            Apply Discount
          </Typography>
      </Box>}

      {/* Collapsible Section */}
     {
        isExpanded&&(
            <Box
        >
          <Typography
            variant="body2"
            sx={{
              marginBottom: 1,
              fontWeight: 'bold',
            }}
          >
            Apply Discount
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:"space-between" }}>
            <TextField
              placeholder="Enter code"
              value={discountCode}
              onChange={(e)=>{setDiscounCode(e.target.value)}}
              variant="outlined"
              size="small"
              sx={{
                // flexGrow: 1,
                // marginRight: 1,
                overflow:"auto",
                minWidth:"500px",
                minHeight:"60px"
              }}
            />
            <Button
              variant="contained"
              size="small"
              onClick={()=>props.appliedDiscount(discountCode)}
              sx={{
                textTransform: 'none',
                right:80,
                top:-10
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
        )
     }
    </Box>
  );
};

export default DiscountCode;
