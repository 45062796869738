import React from "react";
import { Box, Typography, Grid, Button, Divider } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PackageIcon from "@mui/icons-material/InventoryOutlined";

const PackageItemView = () => {
  return (
    <Box
      sx={{
        maxWidth: 400,
        // marginTop:"20px",
        // backgroundColor: "#f9f8f4",
        // padding: 3,
        // borderRadius: 3,
        boxShadow: "3px 4px 10px rgba(0, 0, 0, 0.1)",
        fontFamily: "Roboto, sans-serif",
        borderRadius: "50px",
        padding: 5,
        // boxShadow: 3,
        backgroundColor: "#fff",
      }}
    >
      {/* Best Deal Badge */}
      <Box
        sx={{
          backgroundColor: "#f3e8ff",
          color: "#9c27b0",
          display: "inline-block",
          padding: "4px 12px",
          borderRadius: 2,
          marginBottom: 2,
        }}
      >
        <Typography
          variant="caption"
          sx={{ fontWeight: "bold", fontSize: "12px" }}
        >
          Best Deal
        </Typography>
      </Box>

      {/* Header Section */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          marginBottom: 1,
        }}
      >
        Ultimate job switch package
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "rgba(0, 0, 0, 0.6)",
          marginBottom: 3,
        }}
      >
        Ultimate package for interview prep
      </Typography>

      {/* Items List */}
      {[
        "Interview prep & tips",
        "Mock Interview- 1 Hr",
        "Job Portal profile optimization",
        "Resume review",
      ].map((item, index) => (
        <Grid
          container
          key={index}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: "8px 0",
            borderBottom: index < 3 ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
          }}
        >
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {item}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Video Meeting
            </Typography>
          </Box>
          <Typography
            sx={{
              backgroundColor: "#f5f5f5",
              padding: "4px 8px",
              borderRadius: "16px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "rgba(0, 0, 0, 0.8)",
            }}
          >
            x 1
          </Typography>
        </Grid>
      ))}

      {/* Package Summary Section */}
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          borderRadius: 2,
          padding: 2,
          display: "flex",
          alignItems: "center",
          marginTop: 3,
        }}
      >
        <PackageIcon sx={{ fontSize: "36px", color: "rgba(0, 0, 0, 0.6)" }} />
        <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Package
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "12px" }}
          >
            4 products
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              textDecoration: "line-through",
              fontSize: "12px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            ₹1,086
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#800080",
              color: "#fff",
              textTransform: "none",
              padding: "4px 12px",
              borderRadius: "16px",
              fontWeight: "bold",
              minWidth: "unset",
            }}
          >
            ₹800+
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PackageItemView;
